import stylex from "@stylexjs/stylex";
import { useMachine } from "preact-robot";
import { useEffect, useMemo } from "preact/hooks";
import { createMachine, state, transition } from "robot3";
import { tokens } from "../tokens.stylex";
const spin = "x1wc8ddo-B";
const styles = {
  button: {
    background: "xhz9u8y",
    color: "x4oelfa",
    cursor: "x1ypdohk",
    borderRadius: "x16rqkct",
    display: "xrvj5dj",
    placeItems: "x1ku5rj1",
    padding: "xe8ttls",
    userSelect: "x87ps6o",
    ":focus-visible_outline": "x1oxy70r",
    ":focus-visible_outlineColor": "xw2ayck",
    ":focus-visible_outlineOffset": "x7s97pk",
    $$css: true
  },
  spin: {
    animationName: "x1aerksh",
    animationDuration: "x1q3qbx4",
    animationIterationCount: "xa4qsjk",
    animationTimingFunction: "x1esw782",
    $$css: true
  }
};
const machine = createMachine("idle", {
  idle: state(transition("load", "loading")),
  loading: state(transition("loaded", "playing")),
  playing: state(transition("stop", "idle"))
});
export default function ({
  text
}) {
  const [currentState, send] = useMachine(machine);
  const utterance = useMemo(() => new window.SpeechSynthesisUtterance(text), [text]);
  useEffect(() => {
    utterance.lang = "pt";
    const startEventListener = () => send("loaded");
    const endEventListener = () => send("stop");
    utterance.addEventListener("start", startEventListener);
    utterance.addEventListener("end", endEventListener);
    return () => {
      utterance.removeEventListener("start", startEventListener);
      utterance.removeEventListener("end", endEventListener);
    };
  }, [send, utterance]);
  return <button onClick={() => {
    if (currentState.name === "idle") {
      send("load");
      window.speechSynthesis.speak(utterance);
    } else if (currentState.name === "playing") {
      send("stop");
      window.speechSynthesis.cancel();
    }
  }} type="button" {...stylex.props(styles.button, currentState.name === "loading" && styles.spin)}>
			{currentState.name === "idle" && <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" viewBox="0 0 256 256">
					<title>Play</title>
					<path d="M160,32.25V223.69a8.29,8.29,0,0,1-3.91,7.18,8,8,0,0,1-9-.56l-65.57-51A4,4,0,0,1,80,176.16V79.84a4,4,0,0,1,1.55-3.15l65.57-51a8,8,0,0,1,10,.16A8.27,8.27,0,0,1,160,32.25ZM60,80H32A16,16,0,0,0,16,96v64a16,16,0,0,0,16,16H60a4,4,0,0,0,4-4V84A4,4,0,0,0,60,80Zm126.77,20.84a8,8,0,0,0-.72,11.3,24,24,0,0,1,0,31.72,8,8,0,1,0,12,10.58,40,40,0,0,0,0-52.88A8,8,0,0,0,186.74,100.84Zm40.89-26.17a8,8,0,1,0-11.92,10.66,64,64,0,0,1,0,85.34,8,8,0,1,0,11.92,10.66,80,80,0,0,0,0-106.66Z" />
				</svg>}
			{currentState.name === "loading" && <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" viewBox="0 0 256 256">
					<title>Loading</title>
					<path d="M232,128a104,104,0,0,1-208,0c0-41,23.81-78.36,60.66-95.27a8,8,0,0,1,6.68,14.54C60.15,61.59,40,93.27,40,128a88,88,0,0,0,176,0c0-34.73-20.15-66.41-51.34-80.73a8,8,0,0,1,6.68-14.54C208.19,49.64,232,87,232,128Z" />
				</svg>}
			{currentState.name === "playing" && <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" viewBox="0 0 256 256">
					<title>Stop</title>
					<path d="M216,56V200a16,16,0,0,1-16,16H56a16,16,0,0,1-16-16V56A16,16,0,0,1,56,40H200A16,16,0,0,1,216,56Z" />
				</svg>}
		</button>;
}