import stylex from "@stylexjs/stylex";
import { tokens } from "../tokens.stylex";
export default function ({
  email
}) {
  return <>
			<h1 {...{
      className: "xqozcyj xk50ysn xwn7fz2 x1tfhste"
    }}>Sign in to Leval</h1>
			<form action={`${process.env.API_HOST}/sign-in-code`} method="POST">
				<input hidden name="email" type="email" value={email} />
				<label {...{
        className: "x78zum5 xdt5ytf x1v2ro7d xjv05ge"
      }}>
					Code
					<input name="code" required type="text" {...{
          className: "xf3aw24 xkzqb9i x15thha4 x12oqio5 xneq65o x1xn8qrt x1hr3lfm x1oxy70r xw2ayck x1bqaal"
        }} />
				</label>
				<p {...{
        className: "x4oelfa xwn7fz2 x14vqqas"
      }}>
					We sent a login code to your email address. Paste it here to continue.
				</p>
				<button type="submit" {...{
        className: "x6eipwc x1q4ynmn xtqq3ie x1ypdohk xneq65o xk50ysn xjv05ge x1vvogim x2b8uid x87ps6o xh8yej3 x1oxy70r xw2ayck x7s97pk"
      }}>
					Continue
				</button>
			</form>
		</>;
}