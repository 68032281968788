import stylex from "@stylexjs/stylex";
import { tokens } from "../tokens.stylex";
const styles = {
  l: strokeWidth => [{
    fill: "xbh8q5q",
    stroke: "x11etsk3",
    strokeWidth: "x1ipf9ur",
    $$css: true
  }, {
    "--strokeWidth": strokeWidth != null ? strokeWidth : "initial"
  }],
  level: strokeWidth => [{
    fill: "xbh8q5q",
    stroke: "xa2ycsk",
    strokeWidth: "x1ipf9ur",
    $$css: true
  }, {
    "--strokeWidth": strokeWidth != null ? strokeWidth : "initial"
  }]
};
export default function ({
  size = 24
}) {
  const gapToBorderRatio = 1.618;
  const gap = size / (6 + gapToBorderRatio);
  const borderWidth = gap * gapToBorderRatio;
  return <svg width={size} height={size}>
			<title>Logo</title>
			<path d={`M ${borderWidth / 2} ${gap} v ${size - borderWidth} h ${size}`} {...stylex.props(styles.l(borderWidth))} />
			<path d={`M ${borderWidth + gap} ${gap / 2} h ${size - borderWidth - gap}`} {...stylex.props(styles.level(gap))} />
			<path d={`M ${borderWidth + gap} ${gap / 2 + gap * 2} h ${size - borderWidth - gap}`} {...stylex.props(styles.level(gap))} />
			<path d={`M ${borderWidth + gap} ${gap / 2 + gap * 4} h ${size - borderWidth - gap}`} {...stylex.props(styles.level(gap))} />
		</svg>;
}