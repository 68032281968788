import stylex from "@stylexjs/stylex";
import { useEffect, useRef, useState } from "preact/hooks";
import { tokens } from "../tokens.stylex";
import Banner from "./Banner";
import Button from "./Button";
import Speak from "./Speak";
const styles = {
  letterHint: {
    color: "x19co3pv",
    display: "xrvj5dj",
    placeItems: "x1ku5rj1",
    position: "x1n2onr6",
    ":empty_width": "xisqaeh",
    ":not(:empty)::after_backgroundColor": "x12km8fz",
    ":not(:empty)::after_bottom": "x1dfiv46",
    ":not(:empty)::after_content": "x1k4hda1",
    ":not(:empty)::after_position": "x1jn3xb8",
    ":not(:empty)::after_left": "xb6xxey",
    ":not(:empty)::after_right": "xpxg214",
    ":not(:empty)::after_height": "xau3zej",
    ":not(:empty)::after_transform": "xxatogw",
    ":is(.a)_backgroundColor": "x190hj4g",
    ":is(.a)_color": "xay141m",
    ":is(.b)_backgroundColor": "x1uhk8h",
    ":is(.b)_color": "x1qyv6k4",
    ":is(.c)_backgroundColor": "x1m3eu54",
    ":is(.c)_color": "xiu348q",
    $$css: true
  },
  punctuationHint: {
    color: "x1m0fszw",
    "::after_backgroundColor": "xyhc2n1",
    $$css: true
  },
  letterHintReveal: {
    color: "x1m0fszw",
    $$css: true
  }
};
export default function ({
  match = null,
  onNext,
  onReveal,
  onSubmit,
  reveal,
  sentence,
  translation,
  tuples = []
}) {
  const [dirty, setDirty] = useState(false);
  const ref = useRef();
  useEffect(() => {
    if (match === false && dirty) {
      ref.current.setCustomValidity("Incorrect");
    } else {
      ref.current.setCustomValidity("");
    }
  }, [dirty, match]);
  const h1Ref = useRef();
  const hintRef = useRef();
  useEffect(() => {
    if (!h1Ref.current || !hintRef.current) {
      return;
    }
    const az = "abc";
    tuples.forEach((tuple, i) => {
      const [ptText, enText] = tuple;
      const highlight = new Highlight();
      {
        const start = sentence.toLowerCase().indexOf(ptText);
        const end = start + ptText.length;
        const range = new Range();
        range.setStart(h1Ref.current.firstChild, start);
        range.setEnd(h1Ref.current.firstChild, end);
        highlight.add(range);
      }
      {
        const start = translation.toLowerCase().indexOf(enText);
        const end = start + enText.length;
        for (let j = start; j < end; j++) {
          const child = hintRef.current.children[j];
          child.classList.add(az[i]);
        }
      }
      CSS.highlights.set(az[i], highlight);
    });
  }, [sentence, translation.toLowerCase, tuples]);
  return <form id="sentence" onSubmit={event => {
    event.preventDefault();
    const answer = event.target.answer.value;
    onSubmit(answer);
    setDirty(true);
  }} {...{
    className: "x78zum5 x98rzlu xdt5ytf x1us19tq"
  }}>
			{reveal && match && <Banner variant="success">Well done!</Banner>}

			<div {...{
      className: "x1cy8zhl x78zum5 x1v2ro7d"
    }}>
				<label for="answer">
					<h1 ref={h1Ref} {...{
          className: "xqozcyj xk50ysn xwn7fz2 x1n23rn7 x2wgbds x4z75oe"
        }}>
						{sentence}
					</h1>
				</label>
				<Speak text={sentence} />
			</div>

			<div {...{
      className: "x1ghemps x1eb8jb7 xneq65o xjv05ge x1n2onr6 xtq7m17"
    }}>
				<input disabled={reveal} id="answer" maxLength={translation.trim().length} minLength={0} onInput={() => {
        setDirty(false);
      }} ref={ref} required {...{
        className: "xkzqb9i x15thha4 x12oqio5 x1xn8qrt x1hr3lfm x1n2onr6 xcjijzc xirptt4 xaqnwrm xh8yej3 x1oxy70r xw2ayck x1bqaal x1w5a9lt x12ubzum x1vjfegm"
      }} />
				<div ref={hintRef} {...{
        className: "x14vn8ze x10l6tqk x10a8y8t x47corl x78zum5 x1ml9tup x1xn8qrt x1hr3lfm"
      }}>
					{translation.trim().split("").map(letter => <span key={letter} {...stylex.props(styles.letterHint, /[.,?!']/.test(letter) && styles.punctuationHint, reveal && styles.letterHintReveal)}>
								{letter.trim()}
							</span>)}
				</div>
			</div>

			<div {...{
      className: "xuk3077 x78zum5 x1v2ro7d x13a6bvl x98rzlu x1tfhste"
    }}>
				{reveal && <>
						<div />
						<Button onClick={onNext} type="button" variant="primary">
							Next
						</Button>
					</>}
				{!reveal && <>
						<Button onClick={onReveal} type="button">
							Reveal
						</Button>
						<Button form="sentence" type="submit" variant="primary">
							Submit
						</Button>
					</>}
			</div>
		</form>;
}