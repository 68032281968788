import stylex from "@stylexjs/stylex";
import { useEffect, useState } from "preact/hooks";
import { Link, useLocation, useRoute } from "wouter-preact";
import { tokens } from "../tokens.stylex";
export default function () {
  const [location] = useLocation();
  const [email, setEmail] = useState(undefined);
  useEffect(() => {
    const abortController = new AbortController();
    fetch(`${process.env.API_HOST}/account`, {
      credentials: "include",
      signal: abortController.signal
    }).then(async response => {
      const data = await response.json();
      setEmail(data.email);
    });
    return () => abortController.abort();
  }, []);
  if (location.startsWith("/sign-in")) return null;
  if (email === undefined) return null;
  if (email === null) {
    return <Link href="/sign-in" {...{
      className: "xzx2c2k x1ypdohk x1bvjpef xewurvo xk2swo9 x15pv14y xr0bykp"
    }}>
				Sign in
			</Link>;
  }
  return <form action={`${process.env.API_HOST}/sign-out`} method="POST" {...{
    className: "xuk3077 x78zum5 x1v2ro7d"
  }}>
			<p>{email}</p>
			<button type="submit" {...{
      className: "xzx2c2k x1ypdohk x1bvjpef xewurvo xk2swo9 x15pv14y xr0bykp"
    }}>
				Sign out
			</button>
		</form>;
}