import stylex from "@stylexjs/stylex";
import { tokens } from "../tokens.stylex";
export default function ({
  children
}) {
  return <div {...{
    className: "x595gqu x1xxqvzh x78zum5 xdt5ytf x1ov3xa9 xqp6ajr xaope02"
  }}>
			<div {...{
      className: "x78zum5 x98rzlu xdt5ytf xvueqy4 x11gisft xh8yej3"
    }}>
				<main {...{
        className: "x78zum5 xdt5ytf x98rzlu"
      }}>{children}</main>
			</div>
		</div>;
}