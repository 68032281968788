import { useEffect } from "preact/hooks";
import { useLocation } from "wouter-preact";

export default function ({ packId }) {
	const [, setLocation] = useLocation();

	useEffect(() => {
		const abortController = new AbortController();
		fetch(`${process.env.API_HOST}/pack/${packId}`, {
			credentials: "include",
			method: "POST",
			signal: abortController.signal,
		}).then(async (response) => {
			const attemptId = await response.text();
			setLocation(`/study/${packId}/${attemptId}/0`, { replace: true });
		});
		return () => abortController.abort();
	}, [packId, setLocation]);

	return null;
}
