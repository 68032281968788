import stylex from "@stylexjs/stylex";
import { useEffect, useState } from "preact/hooks";
import Breadcrumb from "./Breadcrumb";
import Breadcrumbs from "./Breadcrumbs";
import PackListItem from "./PackListItem";
export default function () {
  const [packs, setPacks] = useState([]);
  useEffect(() => {
    const abortController = new AbortController();
    fetch(`${process.env.API_HOST}/packs`, {
      credentials: "include",
      signal: abortController.signal
    }).then(async response => {
      const data = await response.json();
      setPacks(data);
    });
    return () => abortController.abort();
  }, []);
  return <>
			<Breadcrumbs>
				<Breadcrumb current description={<>
							The Portuguese language stands out for its phonetic consistency,
							where words are pronounced as they are written, and its rich
							vocabulary that has been influenced by Arabic, African, and Asian
							languages due to Portugal's historical explorations and
							colonization.
						</>} href="/">
					Português
				</Breadcrumb>
			</Breadcrumbs>
			<h1 {...{
      className: "xqozcyj xk50ysn x1159mfc"
    }}>Choose a Pack to study</h1>
			<ul {...{
      className: "x78zum5 xdt5ytf x1v2ro7d xe8uvvx xjv05ge"
    }}>
				{packs.map(({
        attempts,
        count,
        displayName,
        id
      }) => <PackListItem attempts={attempts.reverse()} count={count} href={`/study/${id}`} key={id}>
						{displayName}
					</PackListItem>)}
			</ul>
		</>;
}