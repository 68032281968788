import stylex from "@stylexjs/stylex";
import { tokens } from "../tokens.stylex";
export default function ({
  children,
  current = false,
  description = undefined,
  href
}) {
  return <li>
			<a aria-current={current ? "page" : undefined} href={href} {...{
      className: "xzx2c2k x1fc57z9 x1bvjpef xewurvo x17kaocf xk2swo9 x1tlkk9a x7vsco5 xb5lr1p x1f10rj2"
    }}>
				{children}
			</a>
			{description && <p {...{
      className: "xa7kkou xw7yly9"
    }}>{description}</p>}
		</li>;
}