import { render } from "preact";
import { Link, Route } from "wouter-preact";
import ChoosePack from "./components/ChoosePack";
import Header from "./components/Header";
import Logo from "./components/Logo";
import NewAttempt from "./components/NewAttempt";
import Page from "./components/Page";
import Results from "./components/Results";
import SignInCode from "./components/SignInCode.jsx";
import SignInEmail from "./components/SignInEmail";
import SignInOut from "./components/SignInOut";
import Study from "./components/Study";

render(
	<Page>
		<Header>
			<Link href="/">
				<Logo />
			</Link>
			<SignInOut />
		</Header>
		<Route path="/sign-in">
			<SignInEmail />
		</Route>
		<Route path="/sign-in/:email">
			{(params) => <SignInCode email={params.email} />}
		</Route>
		<Route path="/">
			<ChoosePack />
		</Route>
		<Route path="/study/:packId">
			{(params) => <NewAttempt packId={params.packId} />}
		</Route>
		<Route path="/study/:packId/:attemptId/:offset">
			{(params) => (
				<Study
					attemptId={params.attemptId}
					offset={params.offset}
					packId={params.packId}
				/>
			)}
		</Route>
		<Route path="/results/:packId">
			{(params) => <Results packId={params.packId} />}
		</Route>
	</Page>,
	document.body,
);
