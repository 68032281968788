import stylex from "@stylexjs/stylex";
import { tokens } from "../tokens.stylex";
const styles = {
  button: {
    borderRadius: "x1q4ynmn",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    cursor: "x1ypdohk",
    display: "x1lliihq",
    fontSize: "xneq65o",
    fontWeight: "xk50ysn",
    minWidth: "x1i9suas",
    paddingBlock: "x1vvogim",
    paddingTop: null,
    paddingBottom: null,
    paddingInline: "xdqyycr",
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    textDecoration: "x1hl2dhg",
    textDecorationColor: null,
    textDecorationLine: null,
    textDecorationStyle: null,
    textDecorationThickness: null,
    userSelect: "x87ps6o",
    width: "xeq5yr9",
    ":focus-visible_outline": "x1oxy70r",
    ":focus-visible_outlineColor": "xw2ayck",
    ":focus-visible_outlineOffset": "x7s97pk",
    ":focus-visible_outlineStyle": null,
    ":focus-visible_outlineWidth": null,
    $$css: true
  },
  primary: {
    background: "x6eipwc",
    backgroundAttachment: null,
    backgroundClip: null,
    backgroundColor: null,
    backgroundImage: null,
    backgroundOrigin: null,
    backgroundPosition: null,
    backgroundPositionX: null,
    backgroundPositionY: null,
    backgroundRepeat: null,
    backgroundSize: null,
    color: "xtqq3ie",
    $$css: true
  },
  secondary: {
    background: "xhz9u8y",
    backgroundAttachment: null,
    backgroundClip: null,
    backgroundColor: null,
    backgroundImage: null,
    backgroundOrigin: null,
    backgroundPosition: null,
    backgroundPositionX: null,
    backgroundPositionY: null,
    backgroundRepeat: null,
    backgroundSize: null,
    $$css: true
  }
};
export default function ({
  children,
  variant = "secondary",
  ...props
}) {
  return <button {...props} {...stylex.props(styles.button, styles[variant])}>
			{children}
		</button>;
}