import stylex from "@stylexjs/stylex";
import { Fragment, toChildArray } from "preact";
import { tokens } from "../tokens.stylex";
export default function ({
  children
}) {
  const breadcrumbs = toChildArray(children);
  return <nav>
			<ol {...{
      className: "x6s0dn4 x78zum5 x167g77z xe8uvvx x1sy10c2 xvl6max"
    }}>
				{breadcrumbs.map((breadcrumb, i) => <Fragment key={breadcrumb.href}>
						{breadcrumb}
						{i < breadcrumbs.length - 1 && <svg aria-hidden xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 256 256" {...{
          className: "xeo2xop"
        }}>
								<title>Caret</title>
								<path d="M184.49,136.49l-80,80a12,12,0,0,1-17-17L159,128,87.51,56.49a12,12,0,1,1,17-17l80,80A12,12,0,0,1,184.49,136.49Z" />
							</svg>}
					</Fragment>)}
			</ol>
		</nav>;
}