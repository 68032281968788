import stylex from "@stylexjs/stylex";
import { tokens } from "../tokens.stylex";
const styles = {
  banner: {
    border: "x1ih1xpa",
    borderWidth: null,
    borderInlineWidth: null,
    borderInlineStartWidth: null,
    borderLeftWidth: null,
    borderInlineEndWidth: null,
    borderRightWidth: null,
    borderBlockWidth: null,
    borderTopWidth: null,
    borderBottomWidth: null,
    borderStyle: null,
    borderInlineStyle: null,
    borderInlineStartStyle: null,
    borderLeftStyle: null,
    borderInlineEndStyle: null,
    borderRightStyle: null,
    borderBlockStyle: null,
    borderTopStyle: null,
    borderBottomStyle: null,
    borderColor: null,
    borderInlineColor: null,
    borderInlineStartColor: null,
    borderLeftColor: null,
    borderInlineEndColor: null,
    borderRightColor: null,
    borderBlockColor: null,
    borderTopColor: null,
    borderBottomColor: null,
    borderRadius: "x1q4ynmn",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    fontWeight: "x1s688f",
    marginBottom: "x14ler8",
    $$css: true
  },
  "banner--success": {
    borderColor: "x9e65et",
    borderInlineColor: null,
    borderInlineStartColor: null,
    borderLeftColor: null,
    borderInlineEndColor: null,
    borderRightColor: null,
    borderBlockColor: null,
    borderTopColor: null,
    borderBottomColor: null,
    $$css: true
  },
  content: {
    padding: "xe8ttls",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    $$css: true
  },
  title: {
    paddingBlock: "x1vvogim",
    paddingTop: null,
    paddingBottom: null,
    paddingInline: "xdqyycr",
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    $$css: true
  },
  "title--success": {
    background: "x6eipwc",
    backgroundAttachment: null,
    backgroundClip: null,
    backgroundColor: null,
    backgroundImage: null,
    backgroundOrigin: null,
    backgroundPosition: null,
    backgroundPositionX: null,
    backgroundPositionY: null,
    backgroundRepeat: null,
    backgroundSize: null,
    color: "xtqq3ie",
    $$css: true
  }
};
const title = {
  success: "Correct"
};
export default function ({
  children,
  variant
}) {
  return <div role="region" {...stylex.props(styles.banner, styles[`banner--${variant}`])}>
			<h2 {...stylex.props(styles.title, styles[`title--${variant}`])}>
				{title[variant]}
			</h2>
			<p {...{
      className: "xe8ttls"
    }}>{children}</p>
		</div>;
}