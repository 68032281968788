import { useMachine } from "preact-robot";
import { useEffect, useState } from "preact/hooks";
import { createMachine, invoke, reduce, state, transition } from "robot3";
import { useLocation } from "wouter-preact";
import AnswerForm from "./AnswerForm";
import Breadcrumb from "./Breadcrumb";
import Breadcrumbs from "./Breadcrumbs";

async function matchAnswer(context) {
	const { attemptId, offset, packId } = context;
	const response = await fetch(
		`${process.env.API_HOST}/pack/${packId}/${attemptId}/${offset}`,
		{
			credentials: "include",
			method: "POST",
			headers: {
				"Content-Type": "text/plain",
			},
			body: context.answer,
		},
	);
	if (!response.ok) throw new Error();
}

const machine = createMachine(
	"idle",
	{
		idle: state(
			transition(
				"answer",
				"answering",
				reduce((context, { data }) => ({ ...context, answer: data })),
			),
			transition("reveal", "revealing"),
		),
		answering: invoke(
			(context) => matchAnswer(context),
			transition(
				"done",
				"revealed",
				reduce((context) => ({ ...context, match: true })),
			),
			transition(
				"error",
				"idle",
				reduce((context) => ({ ...context, match: false })),
			),
		),
		revealing: invoke(
			(context) => matchAnswer({ ...context, answer: "" }),
			transition("done", "revealed"),
			transition("error", "revealed"),
		),
		revealed: state(),
	},
	// https://github.com/matthewp/robot/issues/193
	(initialContext) => initialContext,
);

function Study({ attemptId, offset, packId, pair }) {
	const [currentState, send] = useMachine(machine, {
		attemptId,
		offset,
		packId,
		pair,
	});
	const [, setLocation] = useLocation();

	return (
		<>
			<Breadcrumbs>
				<Breadcrumb href="/">Português</Breadcrumb>
				<Breadcrumb current href={`/study/${packId}/${attemptId}/${offset}`}>
					Food
				</Breadcrumb>
			</Breadcrumbs>
			<AnswerForm
				match={currentState.context.match}
				onNext={() => {
					if (currentState.context.pair.done) {
						setLocation("/");
						return;
					}
					setLocation(`/study/${packId}/${attemptId}/${offset + 1}`);
				}}
				onReveal={() => send("reveal")}
				onSubmit={(answer) =>
					send({
						type: "answer",
						data: answer,
					})
				}
				reveal={currentState.name === "revealed"}
				sentence={currentState.context.pair.ptText}
				translation={currentState.context.pair.enText}
				tuples={currentState.context.pair.tuples}
			/>
		</>
	);
}

// TODO show as revealed if answer already exists
export default function ({ attemptId, offset, packId }) {
	const [pair, setPair] = useState(null);

	useEffect(() => {
		const abortController = new AbortController();
		fetch(`${process.env.API_HOST}/pack/${packId}/${attemptId}/${offset}`, {
			credentials: "include",
			signal: abortController.signal,
		}).then(async (response) => {
			const data = await response.json();
			setPair(data);
		});
		return () => abortController.abort();
	}, [attemptId, offset, packId]);

	if (pair === null) return null;

	return (
		<Study
			attemptId={attemptId}
			key={pair.enText}
			// TODO validate offset is a number
			offset={Number(offset)}
			packId={packId}
			pair={pair}
		/>
	);
}
